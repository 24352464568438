<template>
  <div class="thank-you-form">
    <form @submit.prevent="submit" v-form:form novalidate>
      <div class="form-row input-text-field" :class="{'is-error': isErrorForField('customerEmail')}">
        <label for="customerEmail">{{ $t('paywall.form.email.label') }}</label>
        <p role="alert" tabindex="0" v-if="isErrorForField('customerEmail')" class="error" :aria-label="getFieldErrorAriaLabel('customerEmail')">
          <span aria-hidden="true">{{ getFieldErrorText('customerEmail') }}</span>
        </p>
        <div class="input-text-wrapper">
          <input
            type="email"
            name="customerEmail"
            id="customerEmail"
            :placeholder="$t('paywall.form.email.label')"
            email
            required
            @keydown.13.prevent
            v-model.trim="customerEmail" />
          <span class="input-flow-label">&nbsp;{{ $t('paywall.form.email.flowLabel') }}&nbsp;</span>
          <span class="input-flow-mark"></span>
        </div>
      </div>
      <div class="submit-wrapper">
        <div>
          <button type="submit" class="btn" :disabled="isProcessing">
            <span>{{ $t('thankYou.form.buttonSubmit') }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { MESSAGES } from '@/base/const/texts.const'
import { mapActions, mapMutations, mapState } from 'vuex'
import { ToolsClass } from '@/base/class/tools.class'
import FormMixin from '@/base/mixins/form.mixin'

export default {
  name: 'ThankYouForm',
  mixins: [FormMixin],
  computed: {
    ...mapState({
      isProcessing: state => state.payment.processing
    }),
    customerEmail: {
      get () {
        return this.$store.state.payment.form.customerEmail
      },
      set (value) {
        this.SET_PAYMENT_FORM_EMAIL(value)
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_FORM_EMAIL'
    ]),
    ...mapActions([
      'sendPaymentThankYouUpdate',
      'addMessageError'
    ]),
    submit () {
      if (this.form.invalid) {
        this.scrollToError()
        return
      }
      this.sendPaymentThankYouUpdate()
        .then(returnUrl => {
          ToolsClass.goToExternalUrl(returnUrl)
        })
        .catch(error => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('error', error)
          }
          const message = {
            text: MESSAGES().errors.thankYouUpdate
          }
          this.addMessageError(message)
        })
    }
  }
}
</script>
