<template>
  <div>
    <order-details-top :transaction="transaction"
                       :visibility="visibilityOptions"
                       :show-button="false"
                       v-if="canShowMobileOrderDetails"/>
    <main>
      <payment-loading v-if="isLoading"/>
      <div v-if="!isLoading">
        <error-data v-if="isErrorDataSet"/>
        <div v-else>
          <div class="container">
            <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('thankYou.pageTitle') }}</h1>
            <div class="columns confirmation">
              <section class="sidebar-column">
                <order-details-side :transaction="transaction"
                                    :visibility="visibilityOptions"
                                    :show-button="false" />
              </section>
              <section class="main-column">
                <div class="content confirmation-wrapper">
                  <thank-you-pending />
                  <thank-you-form />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss" src="../assets/scss/components/ThankYou.scss"></style>
<script>
import { ERROR_CODES_NAMES } from '@/base/const/errorCodes.const'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { DateClass } from '@/base/class/date.class'
import PaymentLoading from '@/base/components/Payment/Loader/Loading'
import ErrorData from '@/base/components/Error/Data'
import OrderDetailsTop from '@/base/components/OrderDetails/Top'
import OrderDetailsSide from '@/base/components/OrderDetails/Side'
import ThankYouPending from '@/base/components/ThankYou/Pending'
import ThankYouForm from '@/base/components/ThankYou/Form'
import viewMixin from '@/base/mixins/view.mixin'
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'ThankYou',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().thankYou
    }
  },
  mixins: [viewMixin],
  components: {
    ThankYouForm,
    ThankYouPending,
    OrderDetailsTop,
    ErrorData,
    OrderDetailsSide,
    PaymentLoading
  },
  props: {
    apiToken: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.payment.loading,
      isProcessing: state => state.payment.processing,
      transaction: state => state.payment.paymentData ? state.payment.paymentData.transaction : null,
      visibilityOptions: state => state.payment.paymentData ? state.payment.paymentData.visibilityOptions : null
    }),
    ...mapGetters({
      isErrorDataSet: 'isErrorDataSet'
    }),
    canShowMobileOrderDetails: function () {
      return !this.isLoading && !!this.transaction
    }
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA',
      'SET_TIMER_TRX_GET_START',
      'SET_TIMER_TRX_START_LOADED'
    ]),
    ...mapActions([
      'getPaymentThankYouTrx'
    ])
  },
  mounted () {
    const data = {
      apiToken: this.apiToken
    }
    this.SET_TIMER_TRX_GET_START(DateClass.getCurrentTimestamp())
    this.getPaymentThankYouTrx(data)
      .finally(() => {
        this.SET_TIMER_TRX_START_LOADED(DateClass.getCurrentTimestamp())
      })
      .catch(error => {
        const data = {
          errorCode: ERROR_CODES_NAMES.GENERAL_ERROR
        }
        if (error && error.body && error.body.errorCode) {
          data.errorCode = error.body.errorCode
          data.errorUrl = error.body.errorUrl
        }
        this.SET_ERROR_DATA(data)
      })
  }
}
</script>
