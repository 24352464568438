<template>
  <div class="pis-pending">
    <h2 class="normal">{{ $t('thankYou.pending.header') }}</h2>
    <p>{{ $t('thankYou.pending.description') }}</p>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPending'
}
</script>
